import React from 'react';
import styles from './FooterLight.module.scss';

export const FooterLight = ({
  children,
  positionAbsolute = false,
}: {
  children: React.ReactNode | React.ReactNode[];
  positionAbsolute?: boolean;
}) => (
  <footer className={`${styles.footer} ${positionAbsolute ? styles['footer-absolute'] : ''}`} role="contentinfo">
    <div className={styles.container}>{children}</div>
  </footer>
);
