import React from 'react';
import styles from './Row.module.scss';
import { WrapOrders } from '../constants/WrapOrders';
import { JustifyContents } from '../constants/JustifyContents';

interface IRow {
  children: React.ReactNode;
  xxsWrap?: WrapOrders | string;
  lgWrap?: WrapOrders | string;
  xxsJust?: JustifyContents | string;
  lgJust?: JustifyContents | string;
}

export const Row = ({
  children,
  xxsWrap = WrapOrders.ROW,
  lgWrap = WrapOrders.ROW,
  xxsJust = JustifyContents.START,
  lgJust = JustifyContents.START,
}: IRow) => (
  <div
    className={` ${styles.root} ${styles[`xxs-${xxsWrap}`]} ${styles[`lg-${lgWrap}`]} ${styles[`xxs-${xxsJust}`]}
     ${styles[`lg-${lgJust}`]}`}
  >
    {children}
  </div>
);
