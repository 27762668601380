import React from 'react';
import styles from './Column.module.scss';
import { ColumnSizes } from '../constants/ColumnSizes';

interface IColumn {
  children?: React.ReactNode | React.ReactNode[];
  xxs?: ColumnSizes | string;
  xs?: ColumnSizes | string;
  sm?: ColumnSizes | string;
  md?: ColumnSizes | string;
  lg?: ColumnSizes | string;
  xl?: ColumnSizes | string;
  style?: object;
}

export const Column = ({
  children, xxs, xs, sm, md, lg, xl, style = {},
}: IColumn) => (
  <div
    className={`${styles.root} ${xxs ? styles[`xxs-${xxs}`] : ''} ${xs ? styles[`xs-${xs}`] : ''} ${
      sm ? styles[`sm-${sm}`] : ''
    }
       ${md ? styles[`md-${md}`] : ''} ${lg ? styles[`lg-${lg}`] : ''} ${xs ? styles[`xl-${xl}`] : ''} `}
    style={style}
  >
    {children}
  </div>
);
