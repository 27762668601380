/* eslint-disable no-unused-vars */
export enum ColumnSizes {
  FULL = 'full',
  HALF = 'half',
  ONE_FOURTH = 'one-fourth',
  THREE_FOURTHS = 'three-fourths',
  ONE_THIRD = 'one-third',
  TWO_THIRDS = 'two-thirds',
  EMPTY = 'none',
}
