import React from 'react';
import { FooterLight } from './navigation/components/FooterLight';
import { common } from '../content/common';
import { Link } from './Link';

export const FooterMinimal = ({ positionAbsolute = false }: { positionAbsolute?: boolean }) => (
  <FooterLight positionAbsolute={positionAbsolute}>
    <span>ATHENA MORTGAGE PTY LTD | ABN 24 619 536 506 | AUSTRALIAN CREDIT LICENCE 502611</span>
    <Link href="/privacy-policy">PRIVACY POLICY</Link>
    <Link href="/">ATHENA.COM.AU</Link>
    <a href={common.phone.href}>CALL US 13 35 35</a>
  </FooterLight>
);
