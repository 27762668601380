import React from 'react';
import { ColumnSizes } from '@/constants/ColumnSizes';
import dynamic from 'next/dynamic';
import styles from './NotFoundMessage.module.scss';
import { Container } from './Container';
import { Row } from './Row';
import { Column } from './Column';
import { Title } from './Title';
import { Text } from './Text';
import { Button } from './Button';

const LottieRenderer = dynamic(
  () => import('./LottieRenderer').then((comp) => comp.LottieRenderer),
  {
    ssr: false,
  },
);

export const NotFoundMessage = () => (
  <div className={styles.root}>
    <Container>

      <Row>
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          <Title>
            <h1>Page not found</h1>
          </Title>
          <Title size="m">
            <h2>
              We can’t seem to find the page you’re looking for.
              <br />
            </h2>
          </Title>
          <Text>
            Let’s get you back to the home page
          </Text>
          <div className={styles['button-wrapper']}>
            <Button href="/" size="large">
              Take me back
            </Button>
          </div>
        </Column>
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          <LottieRenderer />
        </Column>
      </Row>
    </Container>
  </div>
);
