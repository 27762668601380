import Head from 'next/head';
import React from 'react';
import { Navigation } from '@/components/Navigation';
import type { NextPageWithLayout } from '../types/layoutExtensions';
import { SEO } from '../components/SEO';
import { NotFoundMessage } from '../components/NotFoundMessage';
import { FooterMinimal } from '../components/FooterMinimal';
import { Mode } from '../constants/Mode';

const NotFound: NextPageWithLayout = () => (<NotFoundMessage />);

NotFound.getLayout = (page: React.ReactNode) => (
  <>
    <Head>
      <title>Athena Home Loans | Page Not Found</title>
      <SEO />
    </Head>
    <Navigation mode={Mode.LIGHT} />
    <div className="page">
      {page}
    </div>
    <FooterMinimal positionAbsolute />
  </>
);

export default NotFound;
